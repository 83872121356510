import _ from 'lodash';
import Logger from 'Utils/Logger';

export enum Feature {
    Deductions,
    CertificateGeneration,
    Lmr,
    DownloadIndividualReports,
    FullErrorDetails,
    DevTools,
    NonProdHeaderBackground,
    StandaloneLmr,
    StandaloneOpr,
    Lcr
}

export enum Environment {
    Local,
    Development,
    Demo,
    Staging,
    Production,
}

const EnvironmentMap: Record<string, Environment> = {
    Production: Environment.Production,
    Staging: Environment.Staging,
    Development: Environment.Development,
    Local: Environment.Local,
};
const EnvironmentPrecedence: Environment[] = [Environment.Local, Environment.Development, Environment.Demo, Environment.Staging, Environment.Production];
const DefaultEnvironment: Environment = Environment.Local;
const DefaultEnvironmentName: string = Environment[DefaultEnvironment];

function getEnvironment() {
    const envEnv = process.env['REACT_APP_ENVIRONMENT'];

    if (!envEnv) {
        Logger.warn(`Environmental variable \`REACT_APP_ENVIRONMENT\` is not set. Defaulting to \`${DefaultEnvironmentName}\`.`);
        return DefaultEnvironment;
    } else if (EnvironmentMap[envEnv] !== undefined) {
        Logger.info('REACT_APP_ENVIRONMENT =', envEnv);
        return EnvironmentMap[envEnv];
    } else {
        Logger.warn(
            `\`${envEnv}\` is not a recognized environment value. Must be one of (case-sensitive): ${_.keys(
                EnvironmentMap
            )}. Defaulting to \`${DefaultEnvironmentName}\`.`
        );
        return DefaultEnvironment;
    }
}

export const environment = getEnvironment();

export const isFeatureEnabled = (feature: Feature) => {
    const featureConstraint = Features[feature];
    if (typeof featureConstraint === 'boolean') {
        return featureConstraint as boolean;
    } else if (_.isArray<Feature>(featureConstraint)) {
        return _.includes(featureConstraint, environment);
    } else {
        return (featureConstraint as Environment) === environment;
    }
};

// create a list of environments that is at most as critical as provided value. So max(Develpoment) will include
// Local and Development but no Staging or Production
const max = (maxEnv: Environment): Environment[] => _.takeWhile(EnvironmentPrecedence, (e) => e <= maxEnv);

// Feature constraint can be a
// boolean : true - always enabled, false - always disabled
// an environment : feature is only enabled in that environment
// an array of environments : feature is enabled in all listed environments
// tip: use max(Development) to enable a feature in Local and Development
export const Features: Record<Feature, boolean | Environment | Environment[]> = {
    [Feature.Deductions]: true,
    [Feature.CertificateGeneration]: true,
    [Feature.Lmr]: max(Environment.Demo),
    [Feature.DownloadIndividualReports]: max(Environment.Demo),
    [Feature.FullErrorDetails]: max(Environment.Demo),
    [Feature.DevTools]: max(Environment.Demo),
    [Feature.NonProdHeaderBackground]: max(Environment.Staging),
    [Feature.StandaloneLmr]: max(Environment.Demo),
    [Feature.StandaloneOpr]: max(Environment.Demo),
    [Feature.Lcr]: max(Environment.Demo),
};
