import { AssessmentApi, ModulesModuleType } from '@projectcanary/trustwell-server-client-ts';
import { assessmentApi } from 'Services/TrustwellApiService';

export type DownloadReportData = {
    producerName: string;
    reportDate: Date;
    wellGroup?: { id: number; name: string };
};

export type ReportDownloader = (
    api: AssessmentApi
) =>
    | ((request: { isPreliminary: true; assessmentId: number; reportDate: Date; producerName: string }) => Promise<Response>)
    | ((request: { isPreliminary: true; assessmentId: number; reportDate: Date; producerName: string; wellGroupId: number }) => Promise<Response>);

export type ReportType = {
    reportName: string;
    isPadLevelReport: boolean;
    module: ModulesModuleType;
    downloader: ReportDownloader;
};

export const reportTypes: ReportType[] = [
    {
        reportName: 'OPR Summary Report',
        downloader: (api) => api.downloadWellGroupSummaryReport,
        isPadLevelReport: false,
        module: ModulesModuleType.Opr,
    },
    {
        reportName: 'OPR Well Group Report',
        downloader: (api) => api.downloadWellGroupReport,
        isPadLevelReport: true,
        module: ModulesModuleType.Opr,
    },
    {
        reportName: 'LMR Report',
        downloader: (api) => api.downloadLmrReport,
        isPadLevelReport: true,
        module: ModulesModuleType.Lmr,
    },
    {
        reportName: 'LMR Rating',
        downloader: (api) => api.downloadLmrCertificate,
        isPadLevelReport: true,
        module: ModulesModuleType.Lmr,
    },
];
