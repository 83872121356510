import React from 'react';
import { Box, Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress, Tooltip } from '@mui/material';
import { useNavigation } from 'Utils/Navigation';
import { withDisabledMessage } from 'Utils/withDisabledMessage';
import _ from 'Utils/lodash';

export type Variant = 'primary' | 'secondary' | 'link';

export type ButtonProps = Omit<MuiButtonProps, 'variant' | 'disabled' | 'href' | 'onClick'> & {
    variant: Variant;
    externalLink?: string;
    disabled?: boolean | string;
    href?: string | any;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<any>;
};

const variantMap: { [key in Variant]: MuiButtonProps['variant'] } = {
    primary: 'contained',
    secondary: 'outlined',
    link: 'text',
};

export const Button = (props: ButtonProps) => {
    const { href, externalLink, variant, disabled, onClick, ...restProps } = props;
    const finalProps: typeof restProps & { onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<any> } = { ...restProps };
    const mappedVariant = variantMap[variant];
    const [isBusy, setIsBusy] = React.useState<boolean>(false);

    const { navigateTo, openInNewWindow } = useNavigation();

    if (href && externalLink) {
        throw new Error("Can't have both href and externalLink on a button.");
    } else if (href) {
        finalProps.onClick = () => {
            navigateTo(props.href.toString());
        };
    } else if (externalLink) {
        finalProps.onClick = () => openInNewWindow(externalLink);
    } else if (onClick) {
        finalProps.onClick = (e) => {
            const result = onClick(e);
            if (_.isPromise(result)) {
                setIsBusy(true);
                result.finally(() => setIsBusy(false));
            }
        };
    }

    const innerButton = ({ disabled }: { disabled?: boolean | undefined }) => (
        <Box sx={{ cursor: isBusy ? 'wait !important' : undefined }}>
            <MuiButton variant={mappedVariant} {...finalProps} disabled={!!disabled} startIcon={isBusy ? <CircularProgress size={24} /> : undefined}>
                {props.children}
            </MuiButton>
        </Box>
    );

    return React.createElement(withDisabledMessage(innerButton), { disabled: disabled || isBusy });
};
